import React from "react";

import logo from '../images/logo_main.png';
import vizr from '../images/logo_sec.png';
import door from '../images/door.png';
import ctabg from '../images/cta_bg.png';

import twitter2 from '../images/icons/twitter.png';
import telegram from '../images/icons/telegram.png';


import Link from '../components/link';
import '../App.css';

import '../fonts/pixel/Uni0553.woff';
import '../fonts/pixel/Uni0553.woff2';
import '../fonts/pixel/Uni0553.ttf';

import '../styles/theme.css'
import '../styles/mobile.css'



function LedgerLinkPage() {

    const div1 = React.useRef()
    const div2 = React.useRef()
    const div3 = React.useRef()
    const div4 = React.useRef()

    /**
     * @param {HTMLElement} target 
     */
    function smoothScroll(target) {
        const { top } = target.getBoundingClientRect()
        window.scrollTo({
            top: top + window.pageYOffset,
            behavior: "smooth"
        });
    }

    return (
        <div className="App">
            <nav className="nav">
                <div>
                    <img src={logo} className="logo" alt="logo"
                        onClick={() => smoothScroll(div1.current)} />

                    <input className="nav-btn" type="checkbox" id="menu-btn" />
                    <label className="nav-icon" for="menu-btn"><span className="navicon"></span></label>
                    <div className='nav-items'>
                        <a className="outline" href='/'>Home</a>
                        <span className="button"
                            onClick={() => smoothScroll(div1.current)}>
                            About</span>
                        <span className="button"
                            onClick={() => smoothScroll(div2.current)}>
                            Our Journey</span>
                    </div>
                </div>
            </nav>


            <div className="intro" ref={div1}>
                <div className="container">
                    <div className="content-wrapper">
                        <img src={logo} />
                        <h3>Our deal flow partner network </h3>
                        <p>New technologies power emerging business models that attract new consumer demographics. To tackle the challenges that come along with the disruption of traditional markets, a fresh pair of eyes are key in exploring these exciting opportunities, and that’s exactly what Ledgerlink aims to do. By combining powers within our network, we aim to source projects that have the potential to really thrive within the next few years.  </p>
                        <p>In order to achieve these goals, we focus on nurturing consumer-friendly businesses across an array of horizontal technologies by speaking the language of the consumer. We are a profound believer of new platforms that focus on creating easy-to-use environments where consumers can interact within their preferred trusted environment. That’s why we see great opportunities for business models where the user is being valued as the cornerstone of success.  </p>
                        <p>We could go on and on about our theses, and although the macro economic environment remains unclear, we believe in cautiously taking stakes in the businesses of tomorrow as they will create the new models for mass consumer adoption as well as the new viral growth loops.  </p>
                        <p>Although our team has an excellent track record in building multi-layer consumer environments, we rely on our network to jointly do the heavy lifting and provide added value across all verticals. In short, we aim to share opportunities with those who are closest in our network as shared success is the most sustainable route to exponential growth. </p>
                        <p>So whether you’re an investment vehicle, a development firm or a technology enthusiast, we’re always looking for open-ended relationships to maximize each other’s value.  </p>
                        <a target={'_blank'} href='https://forms.gle/gb3qm5WACt3Kjg4N8'>Partner now</a>
                    </div>
                </div>

            </div>
            <div className="usp timeline" ref={div2} >
                <div className="container">
                    <div className="title"><h2>Our Journey</h2></div>
                    <div className="content-wrapper">
                        <div className="usp-wrapper usp-secondary">
                            <div className="content">
                                <h4>2017-2018</h4>
                                <p>Business ideation / team composition</p>
                            </div>
                            <div className="content">
                                <h4>2019 - 2020</h4>
                                <p>Rapid client and partner growth and team expansion </p>
                            </div>
                            <div className="content">
                                <h4>2022-2023</h4>
                                <p>First acceleration programs </p>
                            </div>

                        </div>
                        <div className="usp-wrapper ">
                            <div className="content">
                                <h4>2018 - 2019</h4>
                                <p>Establishment of Ledgerlink in the current structure</p>
                            </div>

                            <div className="content">
                                <h4>2021 - 2022</h4>
                                <p>First active incubation and advisory programs</p>
                            </div>
                            <div className="content">
                                <h4>2023 - ...</h4>
                                <p>First investment programs (under construction)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="thesis" ref={div3}>
    <div className="door"><img src={door} /></div>
    <div className="container">
        <div className="content-wrapper">
            <h2>More than an accelerator </h2>

            <p>A group of crypto veterans that ran through multiple cycles, we understand every aspect associated to the space in a highly professional and realistic manner.</p><p>LLL is on the cutting edge of the markets because of its multifaceted approach.
                We are in the trenches together with founders, community members, retail traders, consumers and users.</p>

            <p>We are in the war rooms with the institutions discussing theses, strategies and deployment of digital assets. </p>
        </div>
    </div>
</div>*/ }
            <div className="cta" ref={div4}>
                <div className="ctabg"><img src={ctabg} /></div>
                <div className="container">
                    <div className="content-wrapper">
                        <h4>Our network is the cornerstone of our business and we are always looking for interesting opportunities that could benefit our partners.</h4>

                        <a target={'_blank'} href='https://calendly.com/nicolaslll'>Schedule a call</a>
                    </div>
                </div>
            </div>

            <footer>
                <div className="info">
                    <img src={logo} className="logo" alt="logo"
                        onClick={() => smoothScroll(div1.current)} />
                    <div className="socials">
                        <a target={'_blank'} href='https://twitter.com/Ledgerlinklabs'><img src={twitter2} /></a>
                        <a target={'_blank'} href='https://t.me/NicolasLLL'><img src={telegram} /></a>
                    </div>
                    <strong>&copy; 2023 LedgerLink Labs. All rights reserved.</strong>
                </div>
                <div className="links">
                    <strong>Get in touch</strong>
                    <a target={'_blank'} href='https://forms.gle/jzzUw8dHoga78Sr56'>Apply as a Project</a>
                    <a target={'_blank'} href='https://forms.gle/gb3qm5WACt3Kjg4N8'>Apply as a Partner</a>
                </div>

            </footer>
        </div>

    );
}


export default LedgerLinkPage;