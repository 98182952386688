import React from "react";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import logo from '../images/logo_main.png';
import vizr from '../images/logo_sec.png';
import door from '../images/door.png';
import ctabg from '../images/cta_bg.png';

import twitter2 from '../images/icons/twitter.png';
import telegram from '../images/icons/telegram.png';

import doslogo from '../images/projects2/doslogo.svg';
import dospreview from '../images/projects2/3dospreview.png';

import awprev from '../images/projects/awprev.png';
import awlogo from '../images/projects/awlogo.webp';
import ayologo from '../images/projects2/ayologo.png';
import ayopreview from '../images/projects2/ayopreview.png';
import sfpreview from '../images/projects2/sfpreview.png';
import sflogo from '../images/projects2/sflogo.png';

import pllogo from '../images/projects/pllogo.png';
import plpreview from '../images/projects/plpreview.png';

import '../App.css';

import '../fonts/pixel/Uni0553.woff';
import '../fonts/pixel/Uni0553.woff2';
import '../fonts/pixel/Uni0553.ttf';

import '../styles/theme.css'
import '../styles/mobile.css'



function LandingPage() {



    const div1 = React.useRef()
    const div2 = React.useRef()
    const div3 = React.useRef()
    const div4 = React.useRef()
    const div5 = React.useRef()

    const chains = React.useRef()
    const partner = React.useRef()
    /**
     * @param {HTMLElement} target 
     */
    function smoothScroll(target) {
        const { top } = target.getBoundingClientRect()
        window.scrollTo({
            top: top + window.pageYOffset,
            behavior: "smooth"
        });
    }

    (function () {

        var throttle = function (type, name, obj) {
            var obj = obj || window;
            var running = false;
            var func = function () {
                if (running) { return; }
                running = true;
                requestAnimationFrame(function () {
                    obj.dispatchEvent(new CustomEvent(name));
                    running = false;
                });
            };
            obj.addEventListener(type, func);
        };

        throttle("scroll", "optimizedScroll");
    })();

    window.addEventListener("optimizedScroll", function () {

        chains.current.style.transform = "translateX(-" + window.pageYOffset / 9 + "px)";
        partner.current.style.transform = "translateX(" + window.pageYOffset / 13 + "px)";

    })

    return (
        <div className="landing">
            <nav className="nav">
                <div>
                    <img src={logo} className="logo" alt="logo"
                        onClick={() => smoothScroll(div1.current)} />

                    <input className="nav-btn" type="checkbox" id="menu-btn" />
                    <label className="nav-icon" for="menu-btn"><span className="navicon"></span></label>
                    <div className='nav-items'>
                        <span className="button"
                            onClick={() => smoothScroll(div2.current)}>
                            Apply</span>
                        <span className="button"
                            onClick={() => smoothScroll(div3.current)}>
                            Thesis</span>
                        <span className="button"
                            onClick={() => smoothScroll(div4.current)}>
                            Cases</span>
                    </div>
                </div>
            </nav>


            <div className="intro" ref={div1}>
                <div className="container">
                    <div className="content-wrapper">
                        <h1>0 to 1 Technology<br /> Growth Accelerator </h1>
                        <a target={'_blank'} onClick={() => smoothScroll(div2.current)}>Discover</a>
                        <h5>Providing multi-layered guidance and resources to accelerate your growth</h5>
                    </div>
                </div>
                {/*<div id="chains" ref={chains} className="partners">
                    <div className="image-wrapper">
                        <img className="immutable" src={avax} />
                    </div>
                    <div className="image-wrapper">
                        <img src={bsc} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={sol} />
                    </div>
                    <div className="image-wrapper">
                        <img className="near" src={near} />
                    </div>
                    <div className="image-wrapper">
                        <img className="immutable" src={immutable} />
                    </div>

                    <div className="image-wrapper">
                        <img className="solstark" src={stark} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={zklogo} />
                    </div>
                </div>

                <div id="partner" ref={partner} className="partners">

                    <div className="image-wrapper">
                        <img className="solstark" src={ovlogo} />
                    </div>
                    <div className="image-wrapper">
                        <img className="immutable" src={aalogo} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={gotbit} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={seedify} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={kucoin} />
                    </div>
                    <div className="image-wrapper">
                        <img src={kairon} />
                    </div>
                    <div className="image-wrapper">
                        <img className="solstark" src={meter} />
                    </div>


                </div>*/}
            </div>

            <div className="apply" ref={div2} >
                <div className="content-wrapper">
                    <div className="vizr">
                        <div className="content">
                            <img src={vizr} />
                            <h4>Vizr is our acceleration program and studio solution for highly promising projects. Pass our rigid due diligence and fast-track your growth potential through our hands-on approach. We make sure your scaling process keeps running smoothly. </h4>


                        </div>
                        <div className="button-wrapper">
                            <a target={'_blank'} href='https://forms.gle/jzzUw8dHoga78Sr56'>Apply now</a>
                            <a className="outline" href='/vizr'>Read more</a>
                        </div>
                    </div>
                    <div className="lll">
                        <div className="content">
                            <img src={logo} />
                            <h4><br />Ledgerlink is our dedicated network of strategic partners. We provide our partners with valuable insight and interesting opportunities.<br /></h4>

                        </div>
                        <div className="button-wrapper">
                            <a target={'_blank'} href='https://forms.gle/gb3qm5WACt3Kjg4N8'>Partner now</a>
                            <a className="outline" href='/ledgerlink'>Read more</a>
                        </div>
                    </div>

                </div>
            </div>


            <div className="thesis" ref={div3}>
                <div className="door"><img src={door} /></div>
                <div className="container">
                    <div className="content-wrapper">
                        <h2>Execute with actionable insights  </h2>

                        <p>Our team of seasoned professionals have experienced multiple market cycles, which is why we know how to position cutting-edge technologies to take advantage of the challenges and opportunities that arise during transitional market conditions. Staying on top of unfolding trends is what sets us apart and what makes us a preferred advisor for highly promising or established startups as well as investment vehicles. </p>

                        <p>From a project perspective, we understand your needs and emotions. Whether you are just getting started or are looking to scale your growth in a sustainable manner. We focus on targeting the right consumer demographics and making fundamental adjustments by diving into the trenches with you. From groundwork to high-level advisory, we’ve got you covered right from the start. </p>

                        <p>From a partner perspective, we understand the value of network effects. By being in the war rooms with institutions, foundations and investment firms, we’re constantly developing strategies and theses that draw upon our horizontal exposure to new infrastructure technologies and that directly benefit our partners. By serving as a network hub, we pave the way for sustainable growth among our partners.  </p>
                    </div>
                </div>
            </div>
            <div className="portfolio" ref={div4}>
                <div className="container">
                    <div className="content-wrapper">
                        <Tabs>
                            <div className="head">
                                <h2>Projects that preceded you.</h2>
                                <TabList className="tabs">
                                    <Tab>Infra</Tab>
                                    <Tab>Distribution</Tab>
                                    <Tab>Supply Chain</Tab>
                                    <Tab>Entertainment </Tab>
                                    <Tab>Gaming</Tab>

                                </TabList>
                            </div>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="80px" src={plpreview} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="60px" src={pllogo} />
                                        <h6>The Embedded Security, Compute & Revenue Platform</h6>
                                        <p>A solid track record of building systems that scale. Our last launch attracted 2.1M Signups and 60M to our waitlist</p>
                                        <a target={'_blank'} href='https://primelab.io/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="80px" src={ayopreview} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img src={ayologo} />
                                        <h6>Powerful network with disruptive technology and one of a kind products</h6>
                                        <p>Ayozat is a technology and media disruptor driving high performance media, data and distribution services across borders. </p>
                                        <a target={'_blank'} href='https://ayozat.co.uk/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img src={dospreview} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="60px" src={doslogo} />
                                        <h6>3D Printer Cloud Management Software</h6>
                                        <p>An easy to use cloud-based 3D printing management platform for managing files, machines, and users across your business.</p>
                                        <a target={'_blank'} href='https://www.3dprinteros.com/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img src={sfpreview} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img height="30px" src={sflogo} />
                                        <h6>We tell stories in 3D worlds, characters, and experiences.</h6>
                                        <p>Supported by Epic Games and powered by UnrealEngine 5, this boutique studio creates high-end, real-time 3D content, ranging from conceptual design to AAA quality 3D assets, animations, trailers and virtual worlds.</p>
                                        <a target={'_blank'} href='https://www.straightfire.studio'>Learn more</a>
                                    </div>

                                </div>

                            </TabPanel>
                            <TabPanel>
                                <div className="project">
                                    <div className="image-wrapper">
                                        <img height="40px" src={awprev} />
                                    </div>
                                    <div className="content-wrapper">
                                        <img src={awlogo} />
                                        <h6>Souls-like Game</h6>
                                        <p>Abyss World is a third-person ARPG game supported by Epic Games, AMD, Microsoft and many more. Players will explore the traces of the "old tide" in the vast Nordinian world and uncover the secrets behind it.</p>
                                        <a target={'_blank'} href='https://www.abyssworld.games/'>Learn more</a>
                                    </div>

                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
            <div className="cta" ref={div5}>
                <div className="ctabg"><img src={ctabg} /></div>
                <div className="container">
                    <div className="content-wrapper">
                        <h4>Your goal, Our mission.<br /><br />
                            We tailor our services to your needs and focus on the sustainable growth of the digital ecosystem by being agile and staying on top of new market developments
                        </h4>

                        <a target={'_blank'} href='https://calendly.com/nicolaslll'>Schedule a call</a>
                    </div>
                </div>
            </div>

            <footer>
                <div className="info">
                    <img src={logo} className="logo" alt="logo"
                        onClick={() => smoothScroll(div1.current)} />
                    <div className="socials">
                        <a target={'_blank'} href='https://twitter.com/Ledgerlinklabs'><img src={twitter2} /></a>
                        <a target={'_blank'} href='https://t.me/NicolasLLL'><img src={telegram} /></a>
                    </div>
                    <strong>&copy; 2023 LedgerLink Labs. All rights reserved.</strong>
                </div>
                <div className="links">
                    <strong>Get in touch</strong>
                    <a target={'_blank'} href='https://forms.gle/jzzUw8dHoga78Sr56'>Apply as a Project</a>
                    <a target={'_blank'} href='https://forms.gle/gb3qm5WACt3Kjg4N8'>Apply as a Partner</a>
                </div>

            </footer>
        </div>

    );
}


export default LandingPage;